const IMAGES = {
    LOAD: 'IMAGES_LOAD',
    LOAD_SUCCESS: 'IMAGES_LOAD_SUCCESS',
    LOAD_FAIL: 'IMAGES_LOAD_FAILED',
};

const STATS = {
    LOAD: 'STATS_LOAD',
    LOAD_SUCCESS: 'STATS_LOAD_SUCCESS',
    LOAD_FAIL: 'STATS_LOAD_FAILED',
};

export { IMAGES, STATS };
